<template>
  <v-container class="contentContainer">
    <!-- PAGE BANNER - Feedback Title Section -->
    <PageHeader class="pb-4" heading="Feedback"/>
    <!-- MAIN CONTENT AREA - Content Section -->
    <v-row justify="center">
      <v-col class="contentMaxWidth px-0 px-sm-5">
        <!-- Row of feedback cards -->
        <v-row justify="center">
          <v-col v-for="(feedback, idx) in options" :key="idx" lg="4" xs="12" class="feedbackCol">
            <v-card class="feedbackCard py-sm-5 text-center rounded-lg" outlined :style="'background: var(--v-background-base)'">
              <v-card-title class="justify-center">
                <UndrawGift v-if="idx < 1" primary-color='#003c85' height='150px' class="my-12"/>
                <UndrawGroupChat v-else-if="idx < 2" primary-color='#003c85' height='150px' class="my-12"/>
                <UndrawBugFixing v-else primary-color='#003c85' height='150px' class="my-12"/>
              </v-card-title>
              <v-card-text class="text-h5 pb-0 text--text font-weight-bold">{{ feedback.name }}</v-card-text>
              <v-card-text class="pt-2 text--secondary">{{ feedback.text }}</v-card-text>
              <v-card-actions class="px-4 pb-8 pb-md-0 d-flex justify-center">
                <v-btn color="accent" text v-bind:href="options[0].link" target="_blank">{{ feedback.btn }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="justify-center mt-5 mx-3">
          <v-col class="halfCol">
            <p class="text-h5 font-weight-medium text-center mt-12">Support Email</p>
            <p class="text-body-1 text-center">Any other concerns, comments, questions, or account related issues
              can be sent to <a class="accent--text font-weight-medium" href="mailto:uoftindex@outlook.com">
                uoftindex@outlook.com</a></p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import UndrawBugFixing from 'vue-undraw/UndrawBugFixing'
import UndrawGroupChat from 'vue-undraw/UndrawGroupChat'
import UndrawGift from 'vue-undraw/UndrawGift'

export default {
  name: 'Feedback',
  components: { PageHeader, UndrawBugFixing, UndrawGroupChat, UndrawGift },
  data: () => ({
    options: [
      {
        name: 'Feature Request',
        link: 'https://uoftindex.canny.io/feature-requests',
        icon: 'mdi-cake-variant',
        colour: 'warning',
        btn: 'Make Request',
        text: 'If there is something missing that you feel would be a great addition, feel free to make a request' +
          ' for it to be added!'
      },
      {
        name: 'General Feedback',
        link: 'https://forms.gle/4PS9wnaZXSdqnPRNA',
        icon: 'mdi-comment-text',
        colour: 'primary',
        btn: 'Give Feedback',
        text: 'UofT Index was designed by a student, for students! Feel free to leave any feedback that will ' +
          'help better the site.'
      },
      {
        name: 'Bug Report',
        link: 'https://forms.gle/6v41UFpZuME4TCzP9',
        icon: 'mdi-bug',
        colour: 'error',
        btn: 'Report Bug',
        text: 'If you find a bug, please report it! This site will only get better with time, so please be patient' +
          ' and bear with us.'
      }

    ]
  })
}
</script>

<style scoped>
  .feedbackCol {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    border-radius: 10px !important;
  }
  .feedbackCard {
    max-width: 350px;
    min-width: 285px;
    padding: 10px;
  }
  .halfCol {
    max-width: 500px;
  }

</style>
